<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" content="视频列表"/>
		<div class="content">
			<el-table class="tables" :data="newList" ref="productTable" style="width: 100%" stripe
				@selection-change="handleSelect">
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="任务id" width="140">
					<template slot-scope="scope">
						{{task_id}}
					</template>
				</el-table-column>
				<el-table-column label="任务名" width="220">
					<template slot-scope="scope">
						{{scope.row.task_name}}
					</template>
				</el-table-column>
				<el-table-column label="任务状态" width="140">
					<template slot-scope="scope" >
						<div class="box" v-if="scope.row.task_status == 1">
							运行中
						</div>
						<div class="box" v-if="scope.row.task_status == 0">
							已完成
						</div>
						<div class="box" v-if="scope.row.task_status == 2">
							已暂停
						</div>
						<div class="box" v-if="scope.row.task_status == 3">
							用户删除
						</div>
					</template>
				</el-table-column>
				<el-table-column label="视频简介" width="340">
					<template slot-scope="scope">
						{{scope.row.title}}
					</template>
				</el-table-column>
				<el-table-column label="意向客户数" width="140">
					<template slot-scope="scope">
						{{scope.row.video_intended_count}}
					</template>
				</el-table-column>
				<el-table-column label="线索分析数" width="140">
					<template slot-scope="scope">
						{{scope.row.video_comment_count}}
					</template>
				</el-table-column>
				<el-table-column label="挖掘时间" width="200">
					<template slot-scope="scope">
						{{scope.row.time}}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary"  size="mini" @click="jumpKehu(scope.row)">客户</el-button>
						<el-button type="primary"  size="mini" @click="jumpVideo(scope.row.aweme_id)">视频</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="footer">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:current-page.sync="currentPage" :total="total" background>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				erweima: '',
				dialogVisible: false,
				task_id: '', //任务id
				newList: [],
				form: {
					task_id:'',
					status: '',
					create_time:'',
					seller_id: this.$store.state.SELLER_ID
				},
				total: 0, //数据总数
				pageSize: 10, //每页显示数量
				currentPage: 1, //当前页数
				p:0 ,  //总页数
			}
		},
		created() {
			this.task_id = this.$route.query.task_id;
			this.form.task_id = this.$route.query.task_id;
			this.getList()
		},
		methods:{
			handleSizeChange(val) {
				this.pageSize = val
				this.pageNum=val
				this.getList()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.p =val
				this.getList()
			},
			getList() {
				this.$axios.post('/getTaskVideoList', {
					limit:this.pageSize,
					page:this.currentPage,
					seller_id: this.$store.state.SELLER_ID,
					task_id: this.task_id,
				}).then(data => {
					if(data.count){
						this.total = data.count   // 总条数
						// this.p =Math.ceil( data.count / pageSize)   //总页数
					}
					// var start = (currentPage - 1) * pageSize   //开始位置 当前页数 *条数
					// var end = currentPage * pageSize    //结束位置
					// var datas = data.data
					// var c = datas.slice(start,end)
					this.newList = data.data
				})
			},
			jumpVideo(id){
				//跳到抖音详情
				let url = "https://www.douyin.com/video/"+id
				window.open(url,"_blank")
			},
			jumpKehu(row){
				// aweme_id:row.aweme_id,
				this.$router.push({path:`/tiktok/customer`,query:{video_id:row.id,task_id:row.task_id}});
			}
		}
	
	}
</script>

<style scoped>
	.topBox {
		margin: 0 20px;
		padding-top: 10px;
	}
	
	.ships {
		font-size: 12px;
	}
	
	.content {
		padding: 0 28px;
	}
	
	.wrap {
		width: 1600px;
		background-color: #fff;
		padding-bottom: 20px;
	}
	
	.wrap>>>.el-table th {
		background: #e3e4e7;
		color: #120500;
	}
	
	.tableOperate {
		margin: 10px 0px 10px 30px;
	}
	
	.tableOperate .el-button {
		margin-right: 20px;
	}
	
	.wrap>>>.el-page-header {
		padding: 20px;
	}
	
	.forms input {
		width: 216px;
	}
	
	.imgBox {
		display: flex;
		justify-content: center;
		padding-bottom: 20px;
	}
	
	.imgBox img {
		width: 200px;
		height: 200px;
	}
	.footer {
		padding: 75px 0 86px;
		text-align: center;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #ff4344;
	}
	
	.footer>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #C0C4CC;
	}
	.el-table th>.cell{
		text-align: center;
	}
</style>
